var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "user-login-other" }, [
        _c("span", [_vm._v("其他登录方式")]),
        _c(
          "a",
          {
            attrs: { title: "github" },
            on: {
              click: function ($event) {
                return _vm.onThirdLogin("github")
              },
            },
          },
          [
            _c("a-icon", {
              staticClass: "item-icon",
              attrs: { type: "github" },
            }),
          ],
          1
        ),
        _c(
          "a",
          {
            attrs: { title: "企业微信" },
            on: {
              click: function ($event) {
                return _vm.onThirdLogin("wechat_enterprise")
              },
            },
          },
          [
            _c("icon-font", {
              staticClass: "item-icon",
              attrs: { type: "icon-qiyeweixin3" },
            }),
          ],
          1
        ),
        _c(
          "a",
          {
            attrs: { title: "钉钉" },
            on: {
              click: function ($event) {
                return _vm.onThirdLogin("dingtalk")
              },
            },
          },
          [
            _c("a-icon", {
              staticClass: "item-icon",
              attrs: { type: "dingding" },
            }),
          ],
          1
        ),
        _c(
          "a",
          {
            attrs: { title: "微信" },
            on: {
              click: function ($event) {
                return _vm.onThirdLogin("wechat_open")
              },
            },
          },
          [
            _c("a-icon", {
              staticClass: "item-icon",
              attrs: { type: "wechat" },
            }),
          ],
          1
        ),
      ]),
      _c(
        "a-modal",
        {
          attrs: { title: "请输入密码", visible: _vm.thirdPasswordShow },
          on: {
            ok: _vm.thirdLoginCheckPassword,
            cancel: _vm.thirdLoginNoPassword,
          },
        },
        [
          _c("a-input-password", {
            attrs: { placeholder: "请输入密码" },
            model: {
              value: _vm.thirdLoginPassword,
              callback: function ($$v) {
                _vm.thirdLoginPassword = $$v
              },
              expression: "thirdLoginPassword",
            },
          }),
        ],
        1
      ),
      _c(
        "a-modal",
        {
          class: "ant-modal-confirm",
          attrs: {
            footer: null,
            closable: false,
            visible: _vm.thirdConfirmShow,
          },
        },
        [
          _c("div", { staticClass: "ant-modal-confirm-body-wrapper" }, [
            _c(
              "div",
              { staticClass: "ant-modal-confirm-body" },
              [
                _c("a-icon", {
                  staticStyle: { color: "#faad14" },
                  attrs: { type: "question-circle" },
                }),
                _c("span", { staticClass: "ant-modal-confirm-title" }, [
                  _vm._v("提示"),
                ]),
                _c("div", { staticClass: "ant-modal-confirm-content" }, [
                  _vm._v(
                    "\n          已有同名账号存在,请确认是否绑定该账号？\n        "
                  ),
                ]),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "ant-modal-confirm-btns" },
              [
                _c(
                  "a-button",
                  {
                    attrs: { loading: _vm.thirdCreateUserLoding },
                    on: { click: _vm.thirdLoginUserCreate },
                  },
                  [_vm._v("创建新账号")]
                ),
                _c(
                  "a-button",
                  {
                    attrs: { type: "primary" },
                    on: { click: _vm.thirdLoginUserBind },
                  },
                  [_vm._v("确认绑定")]
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c(
        "a-modal",
        {
          class: "ant-modal-confirm",
          attrs: { visible: _vm.bindingPhoneModal },
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "submit",
                  attrs: { type: "primary" },
                  on: { click: _vm.thirdHandleOk },
                },
                [_vm._v("\n        确定\n      ")]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "ant-modal-confirm-body-wrapper" },
            [
              _c("a-form-item", [_c("span", [_vm._v("绑定手机号")])]),
              _c(
                "a-form-item",
                [
                  _c(
                    "a-input",
                    {
                      attrs: {
                        size: "large",
                        type: "text",
                        placeholder: "手机号",
                      },
                      model: {
                        value: _vm.thirdPhone,
                        callback: function ($$v) {
                          _vm.thirdPhone = $$v
                        },
                        expression: "thirdPhone",
                      },
                    },
                    [
                      _c("a-icon", {
                        style: { color: "rgba(0,0,0,.25)" },
                        attrs: { slot: "prefix", type: "mobile" },
                        slot: "prefix",
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "a-row",
                { attrs: { gutter: 16 } },
                [
                  _c(
                    "a-col",
                    { staticClass: "gutter-row", attrs: { span: 16 } },
                    [
                      _c(
                        "a-form-item",
                        [
                          _c(
                            "a-input",
                            {
                              attrs: {
                                size: "large",
                                type: "text",
                                placeholder: "请输入验证码",
                              },
                              model: {
                                value: _vm.thirdCaptcha,
                                callback: function ($$v) {
                                  _vm.thirdCaptcha = $$v
                                },
                                expression: "thirdCaptcha",
                              },
                            },
                            [
                              _c("a-icon", {
                                style: { color: "rgba(0,0,0,.25)" },
                                attrs: { slot: "prefix", type: "mail" },
                                slot: "prefix",
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "gutter-row", attrs: { span: 8 } },
                    [
                      _c("a-button", {
                        staticClass: "getCaptcha",
                        attrs: {
                          tabindex: "-1",
                          disabled: _vm.thirdState.smsSendBtn,
                        },
                        domProps: {
                          textContent: _vm._s(
                            (!_vm.thirdState.smsSendBtn && "获取验证码") ||
                              _vm.thirdState.time + " s"
                          ),
                        },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            $event.preventDefault()
                            return _vm.getThirdCaptcha.apply(null, arguments)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }